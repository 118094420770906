import {
  getEnv, isHIPAA, isEU, getActiveApp, getRevision
} from '@jotforminc/router-bridge';
import trackingList from '@jotforminc/tracking-config';
import { CaptureContext, EventHint, Event } from '@sentry/types';
import { SentryEnhancerOptions } from '@sentry/react/types/redux';

import FullStoryManager from './FullStoryManager';
import SentryManager from './SentryManager';
import {
  FullstoryOptions, ScopeOptions, SentryOptions, FullstoryUserVars
} from './types';

class TrackingManager {
  sentry: SentryManager | undefined;

  sentryOptions: SentryOptions | undefined;

  FS: FullStoryManager | undefined;

  fullstoryOptions: FullstoryOptions | undefined;

  enableSentry(config: object, forceEnable = false): void {
    if (forceEnable || (getEnv() === 'PRODUCTION' && !isHIPAA() && !isEU())) {
      try {
        const activeApp = getActiveApp();
        const doesSentryNameExist = trackingList[activeApp]?.sentry.name;
        const appName = activeApp === 'listings' ? 'js-listings' : activeApp;
        this.sentry = new SentryManager({ ...config, ...(doesSentryNameExist && { release: `${appName}@${getRevision()}` }) });
      } catch (error) {
        console.log('Failed to initialize Sentry !');
      }
    }
  }

  enableFS(userVars: FullstoryUserVars | null = null): void {
    if (getEnv() === 'PRODUCTION' && !isHIPAA() && !isEU()) {
      try {
        if (!this.FSisInitialized()) {
          this.FS = new FullStoryManager({ orgId: 'K1ZZ', debug: false });
        }

        if (userVars) {
          this.setUserVars(userVars);
        }
      } catch (error) {
        console.log('Failed to initialize Fullstory !');
      }
    }
  }

  initTypes = {
    sentry: (config: object, forceEnable: boolean): void => this.enableSentry({ ...config }, forceEnable),
    fullStory: (): void => this.enableFS()
  };

  enableErrorTracking(appName: string, forceEnable = false, extraConfigs: typeof trackingList = {}): void {
    const configs = {
      sentry: {
        ...trackingList?.[appName]?.sentry,
        ...extraConfigs?.sentry
      },
      fullStory: {
        ...trackingList?.[appName]?.fullStory,
        ...extraConfigs?.fullStory
      }
    };
    if (!Object.keys(configs.sentry || {}).length && !Object.keys(configs.fullStory || {}).length) {
      console.log(`Error tracking config couldn't found for ${appName}`);
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const trackingType in configs) {
      if (trackingType !== 'sentry' && trackingType !== 'fullStory') {
        continue;
      }
      const config = configs[trackingType];
      if (config === undefined) continue;
      const { enable, ...rest } = config;
      if (enable) {
        if (!this.initTypes[trackingType]) {
          console.error(`Tracking type ${trackingType} couldn't found`);
          continue;
        }
        this.initTypes[trackingType]({ ...rest }, forceEnable);
      }
    }
  }

  captureException(error: unknown, extras?: CaptureContext): void {
    if (this.sentry) {
      this.sentry?.captureException(error, extras);
    } else if (error instanceof Error) {
      console.log(`Sentry not initialized! \nIf it's initialized, it would capture exception for ${error}\nStack:${error.stack}`);
    } else {
      console.log(`Sentry not initialized! \nIf it's initialized, it would capture exception for ${error}`);
    }
  }

  captureMessage(message: string, extras?: CaptureContext): void {
    if (this.sentry) {
      this.sentry?.captureMessage(message, extras);
    } else {
      console.log(`Sentry not initialized! \nIf it's initialized, it would capture message for ${message}`);
    }
  }

  configureScope(callback: ScopeOptions): void {
    this.sentry?.configureScope(callback);
  }

  configureGlobalScope(callback: ScopeOptions): void {
    this.sentry?.configureGlobalScope(callback);
  }

  captureEvent(event: Event, hint?: EventHint): void {
    this.sentry?.captureEvent(event, hint);
  }

  createReduxEnhancer(enhanceOptions?: Partial<SentryEnhancerOptions>): void {
    return this.sentry?.createReduxEnhancer(enhanceOptions);
  }

  setUserVars(userVars: FullstoryUserVars): void {
    this.FS?.setUserVars(userVars);
  }

  FSisInitialized(): boolean | undefined {
    return this.FS?.isInitialized();
  }

  event(eventName: string, eventProperties: { [key: string]: unknown }): void {
    this.FS?.event(eventName, eventProperties);
  }

  getCurrentSessionURL(): string | undefined | null {
    return this.FS?.getCurrentSessionURL();
  }

  identify(uid: string, customVars?: FullstoryUserVars): void {
    this.FS?.identify(uid, customVars);
  }

  shutdown(): void {
    this.FS?.shutdown();
  }

  restart(): void {
    this.FS?.restart();
  }
}

export default TrackingManager;
